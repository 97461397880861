import React from 'react';

// import woman image
import WhatsAppImage from '../assets/img/whatsapp.png';
import { whatsappContact } from '../data';

const Hero = () => {
  return (
    <section
      id='home'
      className='lg:h-[85vh] flex items-center bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden'
    >
      <a
        href={whatsappContact}
        target='_blank'
        activeClass='active'
        className='fixed-right-button' rel="noreferrer"
      >
        <img src={WhatsAppImage} alt='' className='whatsapp-image' />
      </a>

      <div className='container mx-auto h-full lg:mt-10'>
        <div className='flex items-center h-full pt-8'>
          <div className='flex-1 flex flex-col items-center'>
            <h1 className='text-4xl leading-[44px] sm:text-5xl md:text-6xl md:leading-tight lg:text-4xl xl:text-5xl lg:leading-[1.2] font-bold md:tracking-[-2px] text-center'>
              LAS OPORTUNIDADES NO ESPERAN <br />
            </h1>
            <h1 className='text-4xl leading-[44px] sm:text-5xl md:text-6xl md:leading-tight lg:text-6xl xl:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px] text-tyc text-center'>
              INICIA TU TESIS
            </h1>
            <p className='pt-4 pb-8 md:pt-6 md:pb-12 max-w-[580px] text-xl text-center'>
              <span className='text-white'>¡Sabemos que el tiempo es el mayor limitante! Descuida, no estás solo.</span>
              <span className='text-sec-tyc'> Te acompañaremos en esta nueva aventura</span><span className='text-white'>.</span>
            </p>
            <a
              href={whatsappContact}
              target='_blank'
              activeClass='active'
              className='w-52 h-44 text-xl font-bold transition-all duration-300 btn btn-md bg-tyc hover:bg-whatsapp md:btn-lg transition-all rounded-l-3xl rounded-r-3xl' rel="noreferrer"
            >
              Saber más
            </a>
          </div>
          <div className='hidden lg:flex flex-1 justify-end items-end h-full'>
            <img src='https://static.wixstatic.com/media/80eacb_d494b844390a4a5da5ed395bd1fa3e55~mv2.png/v1/crop/x_673,y_3,w_1067,h_1074/fill/w_568,h_696,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/graduada.png' alt='' width={'440px'} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
