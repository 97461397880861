//  icons
import {
    FiYoutube,
    FiInstagram,
    FiLayout,
    FiSettings,
    FiPenTool,
    FiTag,
    FiPhoneCall,
    FiMapPin,
    FiLinkedin,
    FiFacebook,
    FiCheckCircle,
} from 'react-icons/fi';

// companies icons
import AylinTorres from './assets/img/team/aylin_torres.png';
import CruzNieves from './assets/img/team/cruz_nieves.png';
import GustavoPonciano from './assets/img/team/gustavo_ponciano.png';
import LuceroLumbre from './assets/img/team/lucero_lumbre.png';
import MaribelAlvarez from './assets/img/team/maribel_alvarez.png';
import MelanyCairampoma from './assets/img/team/melany_cairampoma.png';
import NormaTorres from './assets/img/team/norma_torres.png';
import OliverGallo from './assets/img/team/oliver_gallo.png';
import PetraSanchez from './assets/img/team/petra_sanchez.png';
import YajairaPineda from './assets/img/team/yajaira_pineda.png';
import Leonardo from './assets/img/team/leonardo.jpg';
import Hilda from './assets/img/team/hilda.jpg';
import Lindsay from './assets/img/team/lindsay.jpg';
import Julissa from './assets/img/team/julissa.jpg';
import Daniela from './assets/img/team/daniela.jpg';
import Juan from './assets/img/team/juan.jpg';
import Dina from './assets/img/team/dina.jpg';
import Mercedes from './assets/img/team/mercedes.jpg';
import Daniel from './assets/img/team/daniel.jpg';
import Zaida from './assets/img/team/zaida.jpg';
import Ricardo from './assets/img/team/ricardo.jpg';
import CarliuskaJaramillo from './assets/img/team/carliuska_jaramillo.jpg';

// testimonial images
import TestiImage1 from './assets/img/testimonials/testimonial-1.webp';
import TestiImage2 from './assets/img/testimonials/testimonial-2.webp';
import TestiImage3 from './assets/img/testimonials/testimonial-3.webp';

// navigation
export const navigation = [
    {
        name: 'INICIO',
        href: 'home',
    },
    {
        name: 'NOSOTROS',
        href: 'about',
    },
    {
        name: 'EQUIPO',
        href: 'portfolio',
    },
    {
        name: 'SERVICIOS',
        href: 'services',
    },
    {
        name: 'TESTIMONIOS',
        href: 'testimonials',
    },
];

// social
export const social = [
    {
        icon: <FiYoutube />,
        href: 'https://www.youtube.com/channel/UCU-Cqvnn4KwmtsqMXnBnNIg',
    },
    {
        icon: <FiLinkedin />,
        href: 'https://www.linkedin.com/company/14040761/admin/',
    },
    {
        icon: <FiFacebook />,
        href: 'https://www.facebook.com/torreycaballoasesores/',
    },

    {
        icon: <FiInstagram />,
        href: 'https://www.instagram.com/asesoriadetesis.tyc/',
    },
];

// companies
export const brands = [
    {
        icon: <FiCheckCircle />,
        title: 'Seguridad',
    },
    {
        icon: <FiCheckCircle />,
        title: 'Compromiso',
    },
    {
        icon: <FiCheckCircle />,
        title: 'Ética',
    },
    {
        icon: <FiCheckCircle />,
        title: 'Facilidades de pago',
    },
    {
        icon: <FiCheckCircle />,
        title: 'Material audiovisual',
    },
];

// projects
export const projectsData = [
    {
        id: '1',
        image: OliverGallo,
        name: 'Oliver Gallo Torres',
        category: 'Administración',
        linkedin: "https://www.linkedin.com/in/oliver-gallo-torres-9a655a125/",
        linkedinIcon: <FiLinkedin />,
        youtube: "https://youtube.com/@tesistorreycaballo8670",
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '2',
        image: NormaTorres,
        name: 'Norma Torres Ruíz',
        category: 'Administración',
        linkedin: "https://www.linkedin.com/in/norma-torres-960704248/",
        linkedinIcon: <FiLinkedin />,
        youtube: "https://youtube.com/@tesistorreycaballo8670",
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '3',
        image: Leonardo,
        name: 'Leonardo Villafuerte Mauricio',
        category: 'Administración',
        linkedin: "https://www.linkedin.com/in/leonardovillafuerte/",
        linkedinIcon: <FiLinkedin />,
        youtube: "https://youtube.com/@tesistorreycaballo8671",
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '4',
        image: PetraSanchez,
        name: 'Petra Sanchez De Quijada',
        category: 'Investigación',
        linkedin: "https://www.linkedin.com/in/petra-s%C3%A1nchez-de-quijada-750b5524b/",
        linkedinIcon: <FiLinkedin />,
        youtube: "https://www.youtube.com/watch?v=iDr1S07Lvn8&t=70s",
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '5',
        image: CruzNieves,
        name: 'Cruz Nieves Olivares',
        category: 'Investigación',
        linkedin: "https://www.linkedin.com/in/cruz-nieves-olivares-39a21320b/",
        linkedinIcon: <FiLinkedin />,
        youtube: "https://www.youtube.com/watch?v=6BN2LfajQX0",
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '6',
        image: MaribelAlvarez,
        name: 'Maribel Alvarez Huaynalaya',
        category: 'Investigación',
        linkedin: null,
        linkedinIcon: <FiLinkedin />,
        youtube: "https://www.youtube.com/watch?v=IJi6Z2xpvv4",
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '7',
        image: MelanyCairampoma,
        name: 'Melany Cairampoma Avellaneda',
        category: 'Investigación',
        linkedin: "https://www.linkedin.com/in/melany-crystel-cairampoma-avellaneda-651922163/",
        linkedinIcon: <FiLinkedin />,
        youtube: 'https://www.youtube.com/watch?v=Bb2VW4Fw1BM',
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '8',
        image: CarliuskaJaramillo,
        name: 'Carliuska Jaramillo Zamora',
        category: 'Investigación',
        linkedin: 'https://www.linkedin.com/in/mar%C3%ADa-carliuska-jaramillo-zamora-4525a7248/',
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '9',
        image: Hilda,
        name: 'Hilda Cuba Borda',
        category: 'Investigación',
        linkedin: null,
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    // {
    //     id: '9',
    //     image: XiomaraVergaray,
    //     name: 'Priscilla Vergaray Candia',
    //     category: 'Investigación',
    //     linkedin: "https://www.linkedin.com/in/priscilla-xiomara-vergaray-candia-223893215/",
    //     linkedinIcon: <FiLinkedin />,
    //     youtube: null,
    //     youtubeIcon: <FiYoutube />,
    // },
    {
        id: '10',
        image: AylinTorres,
        name: 'Aylin Torres Guffanti',
        category: 'Investigación',
        linkedin: "https://www.linkedin.com/in/aylin-torres-guffanti28/",
        linkedinIcon: <FiLinkedin />,
        youtube: 'https://www.youtube.com/watch?v=MhCC5PodYhQ',
        youtubeIcon: <FiYoutube />,
    },
    // {
    //     id: '11',
    //     image: RusellCasimiro,
    //     name: 'Russel Casimiro Dionicio',
    //     category: 'Investigación',
    //     linkedin: "https://www.linkedin.com/in/russell-casimiro-343947254/",
    //     linkedinIcon: <FiLinkedin />,
    //     youtube: "https://www.youtube.com/watch?v=08xK20R1i_U",
    //     youtubeIcon: <FiYoutube />,
    // },
    // {
    //     id: '13',
    //     image: SamantaSotelo,
    //     name: 'Samanta Sotelo Llancari',
    //     category: 'Investigación',
    //     linkedin: "https://www.linkedin.com/in/samantha-sotelo-23a056248/",
    //     linkedinIcon: <FiLinkedin />,
    //     youtube: "https://www.youtube.com/watch?v=sUZoaepsQwI&t=10s",
    //     youtubeIcon: <FiYoutube />,
    // },
    {
        id: '11',
        image: Lindsay,
        name: 'Lindsay Gonzales Canchanya',
        category: 'Investigación',
        linkedin: "https://www.linkedin.com/in/lindsay-gonzales-2a62a4248/",
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    // {
    //     id: '15',
    //     image: JackelineRamirez,
    //     name: 'Jackelyne Ramírez carmona',
    //     category: 'Investigación',
    //     linkedin: "https://www.linkedin.com/in/jackelyne-ram%C3%ADrez-884641246/",
    //     linkedinIcon: <FiLinkedin />,
    //     youtube: null,
    //     youtubeIcon: <FiYoutube />,
    // },
    {
        id: '12',
        image: GustavoPonciano,
        name: 'Gustavo Ponciano Altamirano',
        category: 'Investigación',
        linkedin: "https://www.linkedin.com/in/gustavo-alfredo-ponciano-altamirano-806b4937/",
        linkedinIcon: <FiLinkedin />,
        youtube: "https://www.youtube.com/watch?v=8zk29jG31S0&t=31s",
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '13',
        image: Julissa,
        name: 'Julissa Espinoza Carrión',
        category: 'Investigación',
        linkedin: null,
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '14',
        image: Daniela,
        name: 'Daniela Urbina Machuca',
        category: 'Investigación',
        linkedin: null,
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '15',
        image: Juan,
        name: 'Juan Uvaldo Vergary',
        category: 'Investigación',
        linkedin: null,
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '16',
        image: Dina,
        name: 'Dina Mallqui',
        category: 'Investigación',
        linkedin: null,
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '17',
        image: Ricardo,
        name: 'Ricardo Mendez Crisóstomo',
        category: 'Investigación',
        linkedin: null,
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    // {
    //     id: '17',
    //     image: JessicaOrdones,
    //     name: 'Jessica Ordoñez Diaz',
    //     category: 'Marketing',
    //     linkedin: "https://www.linkedin.com/in/jessica-ordo%C3%B1ez-050676251/",
    //     linkedinIcon: <FiLinkedin />,
    //     youtube: null,
    //     youtubeIcon: <FiYoutube />,
    // },
    {
        id: '18',
        image: LuceroLumbre,
        name: 'Lucero Lumbre Quispe',
        category: 'Marketing',
        linkedin: "https://www.linkedin.com/in/lucerolumbreq15/",
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,

    },
    {
        id: '19',
        image: Mercedes,
        name: 'Mercedes Campo Trinidad',
        category: 'Marketing',
        linkedin: "https://www.linkedin.com/in/mercedes-campos-trinidad-040086120/",
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '20',
        image: Daniel,
        name: 'Daniel Díaz Chavez',
        category: 'Marketing',
        linkedin: "https://www.linkedin.com/in/daniel-d%C3%ADaz-591357162/",
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '21',
        image: Zaida,
        name: 'Zaida Artica Caballero',
        category: 'Administración',
        linkedin: "https://www.linkedin.com/in/zaida-caballero-437850169/",
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '22',
        image: YajairaPineda,
        name: 'Yajaira Pineda Sulca',
        category: 'Marketing',
        linkedin: "https://www.linkedin.com/in/yajaira-pineda-sulca-93463a245/",
        linkedinIcon: <FiLinkedin />,
        youtube: "https://www.youtube.com/watch?v=UpM87dmwKoc",
        youtubeIcon: <FiYoutube />,
    },
    {
        id: '23',
        image: null,
        name: 'Dante Gallo Torres',
        category: 'Ingeniería',
        linkedin: null,
        linkedinIcon: <FiLinkedin />,
        youtube: null,
        youtubeIcon: <FiYoutube />,

    },

    // {
    //     id: '21',
    //     image: LeslyBarrantes,
    //     name: 'Lesly Barrantes Paredes',
    //     category: 'Marketing',
    //     linkedin: "https://www.linkedin.com/in/lesly-barrantes-8b4114248/",
    //     linkedinIcon: <FiLinkedin />,
    //     youtube: null,
    //     youtubeIcon: <FiYoutube />,

    // }
];

// projects
export const projectsNav = [
    {
        name: 'Todos',
    },
    {
        name: 'Administración',
    },
    {
        name: 'Investigación',
    },
    {
        name: 'Ingeniería',
    },
    {
        name: 'Marketing',
    },
];

// skill
export const company = [
    {
        title: "Misión",
        text: "Lograr el éxito y logro de nuestros estudiantes, que estos obtengan su título profesional y alcancen el grado académico que anhelan.",
    },
    {
        title: "Visión",
        text: "Ser la empresa consultora líder en servicios de asesoría de trabajos de investigación de pregrado y posgrado.",
    },
];

// services
export const services = [
    {
        icon: <FiLayout />,
        name: 'TESIS DESDE CERO',
        description: '- Asesoría personalizada (2 asesores), monitoreo paso a paso. <br /> - Consultas ilimitadas y sesiones de capacitación. <br /> - Capacitación y material audiovisual para sustentación.',
    },
    {
        icon: <FiSettings />,
        name: 'TESIS PARA GENTE QUE TRABAJA',
        description: '- Asesoría personalizada (2 asesores), monitoreo paso a paso. <br/> - Consultas ilimitadas y sesiones de capacitación. <br/> - Capacitación y material audiovisual para sustentación.',
    },
    {
        icon: <FiPenTool />,
        name: 'CORRECCIÓN DE ESTILO',
        description: '- Nos adaptamos a los requerimientos de tu asesor. <br/> - Asesoría personalizada (1 asesor), monitoreo paso a paso. <br/> - Corrección de redacción. <br/> - Adaptación a la normativa solicitada por la institución.'
    },
    {
        icon: <FiTag />,
        name: 'DIAGNÓSTICO Y CORRECCIÓN DE TESIS',
        description: '- Asesoría personalizada (2 asesores), monitoreo paso a paso. <br/> - Informe de grado de plagio y corrección(parafraseo). <br/> - Corrección de fondo y forma (marco teórico).'
    },
];

// testimonials
export const testimonials = [
    {
        authorImg: TestiImage1,
        authorText:
            'Agradecida por el asesoramiento del equipo TORRE y CABALLO, ya que gracias a su excelente labor la sustentación del día 20 fue un éxito. En verdad los recomiendo.',
        authorName: 'Rocío Escudero Jimenez',
        authorPosition: 'Enfermería',
    },
    {
        authorImg: TestiImage2,
        authorText:
            'Gracias Torre y Caballo Asesores por la gran ayuda, son un equipo de profesionales comprometidos con nuestro objetivo, estoy sumamente contenta con los resultados obtenidos.RECOMENDADISIMOSSS!!!!',
        authorName: 'Gisella Cadenillas',
        authorPosition: 'Administración de empresas',
    },
    {
        authorImg: TestiImage3,
        authorText:
            'Recomiendo al grupo de asesores Torre y Caballo Asesores por su profesionalismo y el compromiso para desarrollar los temas.',
        authorName: 'Jorge Enrique Jerí Roldán',
        authorPosition: 'Negocios',
    },
    {
        authorImg: TestiImage3,
        authorText:
            'Estoy muy contento con su asesoría, me sirvió de mucho, cambió toda mi perspectiva, gracias a su asesoramiento pude aprobar el examen y titularme. Muchas gracias.',
        authorName: 'Angelo Felipe Sosa',
        authorPosition: 'Administración',
    },
    {
        authorImg: TestiImage3,
        authorText:
            'Agradecida por su apoyo al equipo de asesores Torre y Caballo por brindarme un buen servicio, sobre todo por su paciencia, amabilidad, trato y sobre todo compromiso, desde el principio hasta el final, excelente profesionales 100% recomendado.',
        authorName: 'Olguita Quispe',
        authorPosition: 'Administración',
    },
    {
        authorImg: TestiImage3,
        authorText:
            'Agradezco su apoyo al equipo de Torre y Caballo Asesores, por el asesoramiento constante para presentar un excelente trabajo,fue un gusto trabajar con un buen grupo frofesional. Lo recomiendo 100%.',
        authorName: 'Katiuska Edith Julcarima Paucar',
        authorPosition: 'Administración',
    },
    {
        authorImg: TestiImage3,
        authorText:
            'Gracias por el apoyo, Torre y Caballo Asesores, un excelente grupo de asesores, con muchísimo profesionalismo y paciencia, muy aparte de ello te brinda un seguimiento para las correcciones de los temas presentados para que así puedas llegar sin errores al trabajo final. Recomendadísimo al 100.',
        authorName: 'Yunior Figueroa',
        authorPosition: 'Administración',
    },
    {
        authorImg: TestiImage3,
        authorText:
            'Agradezco a torre y Caballo Asesores por la ayuda a su equipo de profesionales, así como al asesor Russell Laudrup Casimiro Dionicio por haberme brindado su tiempo, su capacidad y conocimiento científico, a la vez haberme tenido toda la paciencia del mundo para guiarme durante todo el desarrollo de mi Tesis para lograr los objetivos esperados.',
        authorName: 'Milagros Chauca',
        authorPosition: 'Administración',
    },
];

// contact
export const contact = [
    {
        icon: <FiPhoneCall />,
        title: 'Comunícate con uno de nuestros asesores',
        subtitle: 'Tesifono',
        description: '985764016',
    },
    {
        icon: <FiMapPin />,
        title: 'Dirección',
        subtitle: 'Los Olivos, Lima',
        description: 'Santa Elisa Iii Etapa (Colegio Latinoamericano)',
    },
];

export const whatsappContact = "https://api.whatsapp.com/send?phone=985764016&text=Hola%20, quiero%20más%20información.";