import React from 'react';

// import img
import DraPetra from '../assets/img/parts/dra.png';
import { whatsappContact } from '../data';

const About = () => {
  return (
    <section className=' bg-secondary' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-24'>
          <img
            className='object-cover h-full w-[586px] md:mx-auto lg:mx-0 rounded-2xl'
            src={DraPetra}
            alt=''
          />
          <div className='flex flex-col items-center text-center lg:items-start lg:text-left'>
            <div className='flex flex-col font-semibold'>
              <h2 className='text-3xl lg:text-4xl font-medium lg:font-extrabold mt-20 mb-2 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block'>
                Torre y Caballo
              </h2>
              <p className='mb-4 text-tyc'>
                Sabemos que la tesis es el paso mas importante
              </p>
              <hr className='mb-8 opacity-5' />
              <p className='mb-8'>
                En Torre y Caballo Asesores estamos comprometidos con tus sueños. Te acompañamos en cada etapa de tu desarrollo profesional,
                brindándote las herramientas que necesitas para alcanzar resultados exitosos.

                <br />
                <br />

                Nuestro objetivo es lograr que obtengas el éxito profesional que mereces y no dejes pasar más las oportunidades laborales por
                no concluir tu tesis.
              </p>
            </div>
            <br />
            <a
              href={whatsappContact}
              target='_blank'
              activeClass='active'
              className='w-48 h-44 text-xl font-bold transition-all duration-300 btn btn-md bg-tyc hover:bg-whatsapp md:btn-lg transition-all rounded-l-3xl rounded-r-3xl' rel="noreferrer"
            >
              Contáctanos
            </a>
          </div>
        </div>
      </div>

      {/* <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-24'>
          <img
            className='object-cover h-full w-[1566px] md:mx-auto lg:mx-0 rounded-2xl'
            src={TeamImage}
            alt=''
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br /> */}

    </section>
  );
};

export default About;
