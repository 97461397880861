import React from 'react';

const Project = ({ item }) => {
  return (
    <div key={item.id} className='flex flex-col items-center text-center'>
      <div className='mb-8'>
        <img className='rounded-2xl' src={item.image} alt='' />
      </div>
      <p className='capitalize text-white text-sm mb-3'>{item.category}</p>
      <h3 className='text-2xl font-semibold capitalize mb-3'>{item.name}</h3>
      <div className='flex'>
        <a className='text-lg text-accent' href={item.linkedin} target="_blank">
          {item.linkedinIcon}
        </a>
        <a className='text-lg ml-4 text-red-700' href={item.youtube} target="_blank">
          {item.youtubeIcon}
        </a>
      </div>
    </div>
  );
};

export default Project;
