import React from 'react';

// import skill data
import { company } from '../data';

const Skills = () => {
  return (
    <section className='bg-tertiary py-12'>
      <div className='container mx-auto'>
        <div
          className='flex xl:flex-row lg:flex-row flex-col gap-x-32'
        >
          {company.map((item, index) => {
            return (
              <div
                className='text-center justify-center flex-1 my-4'
                key={index}
              >
                <h4 className='text-3xl text-tyc font-extrabold'>{item.title}</h4>
                <br />
                <p className='ml-3 mr-3 font-semibold'>{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Skills;
