import React from 'react'

import Ben1 from '../assets/img/parts/ben1.png';
import Ben2 from '../assets/img/parts/ben2.png';
import Ben3 from '../assets/img/parts/ben3.png';


const AboutSecond = () => {
    return (
        <section className='section bg-black'>
            <div className='container mx-auto'>
                <div className='flex flex-col items-center text-center'>
                    <h2 className='section-title active before:content-benefits text-tyc relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-full before:hidden before:lg:block'>
                        BENEFICIOS
                    </h2>
                    <p className='subtitle'>
                        ¿Porqué elegirnos?
                    </p>
                </div>

                <div className='container mx-auto'>
                    <div className='flex flex-col xl:flex-row gap-24'>

                        <div className='flex flex-col items-center text-center lg:items-start lg:text-left'>
                            <div className='flex flex-col'>

                                <div className='flex flex-col xl:flex-row lg:flex-row gap-24'>
                                    <div className=''>
                                        <img
                                            className='object-cover w-[106px] md:mx-auto lg:mx-0 rounded-2xl'
                                            src={Ben1}
                                            alt=''
                                        />
                                        <p className='mb-4 text-tyc text-2xl font-bold'>
                                            Seguridad
                                        </p>
                                        <ul className='font-semibold'>
                                            <li>
                                                * Somos una empresa registrada en los Registros Públicos.
                                            </li>
                                            <li>
                                                * Contamos con profesionales en Metodología de la investigación.
                                            </li>
                                            <li>
                                                * Aseguramos protección de la información.
                                            </li>
                                            <li>
                                                * Contrato de prestación de servicios.
                                            </li>
                                        </ul>
                                    </div>

                                    <div className=''>
                                        <img
                                            className='object-cover w-[106px] md:mx-auto lg:mx-0 rounded-2xl'
                                            src={Ben2}
                                            alt=''
                                        />
                                        <p className='mb-4 text-tyc text-2xl font-bold'>
                                            Facilidades de pago
                                        </p>
                                        <ul className='font-semibold'>
                                            <li>
                                                * Pagos en cuotas.
                                            </li>
                                            <li className='whitespace-nowrap'>
                                                * Sin intereses, ni recargas.
                                            </li>
                                            <li>
                                                * Flexibilidad en los pagos.
                                            </li>
                                        </ul>
                                    </div>

                                    <div className=''>
                                        <img
                                            className='object-cover w-[106px] md:mx-auto lg:mx-0 rounded-2xl'
                                            src={Ben3}
                                            alt=''
                                        />
                                        <p className='mb-4 text-tyc text-2xl font-bold'>
                                            Sistema de trabajo
                                        </p>
                                        <ul className='font-semibold'>
                                            <li>
                                                * Ofrecemos asesorías personalizadas.
                                            </li>
                                            <li>
                                                * Brindamos informes semanales de los avances.
                                            </li>
                                            <li>
                                                * Aseguramos retroalimentación asesor-estudiante en cada avance.
                                            </li>
                                            <li>
                                                * Realizamos sesiones presenciales / virtuales de asesoría.
                                            </li>
                                            <li>
                                                * Resolvemos todas las consultas.
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSecond;
