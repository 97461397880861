import React from 'react';

// import brands data
import { brands } from '../data';

const Brands = () => {
  return (
    <section className='min-h-[146px] bg-tertiary flex items-center'>
      <div
        className='container mx-auto lg:flex md:justify-between items-center flex-wrap justify-evenly md:block'
      >
        {brands.map((brand, idx) => {
          return (
            <div
              key={idx}
              className='flex my-5 justify-center'
            >
              <a className='text-lg text-white mr-2 mt-1'>
                {brand.icon}
              </a>
              <p className='text-lg text-white'>{brand.title}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Brands;
